var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import '@solana/wallet-adapter-react-ui/styles.css';
export const WalletButtonComponent = () => {
    const { publicKey, connected, disconnect, signTransaction } = useWallet();
    useEffect(() => {
        if (connected && publicKey) {
            window.wallet = {
                publicKey,
                disconnect,
                signTransaction: (transaction) => __awaiter(void 0, void 0, void 0, function* () {
                    if (!signTransaction)
                        throw new Error('Wallet does not support signing');
                    return signTransaction(transaction);
                })
            };
            window.dispatchEvent(new Event('walletChange'));
        }
        else {
            window.wallet = null;
        }
    }, [connected, publicKey, disconnect, signTransaction]);
    const buttonText = connected ? (publicKey === null || publicKey === void 0 ? void 0 : publicKey.toBase58().slice(0, 4)) + '..' + (publicKey === null || publicKey === void 0 ? void 0 : publicKey.toBase58().slice(-4)) : 'CONNECT';
    return (_jsx(WalletMultiButton, { className: "custom-wallet-btn", children: buttonText }));
};
