var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PublicKey } from '@solana/web3.js';
import { Program, AnchorProvider } from '@project-serum/anchor';
import { connection, programID } from './script';
import idl from './rapr_mvp.json';
export function getProgramState() {
    return __awaiter(this, void 0, void 0, function* () {
        console.log('Fetching program state');
        const provider = new AnchorProvider(connection, {}, AnchorProvider.defaultOptions());
        const program = new Program(idl, programID, provider);
        const [programState] = yield PublicKey.findProgramAddress([Buffer.from('program_state')], programID);
        console.log('Program state PDA:', programState.toBase58());
        try {
            const account = yield program.account.programState.fetch(programState);
            console.log('Program state fetched:', account);
            return account;
        }
        catch (error) {
            console.error('Error fetching program state:', error);
            return null;
        }
    });
}
export function createPopup(message, onConfirm, onCancel) {
    const overlay = document.createElement('div');
    overlay.className = 'popup-overlay';
    const popup = document.createElement('div');
    popup.className = 'popup-content';
    const messageElement = document.createElement('p');
    messageElement.innerHTML = message;
    messageElement.className = 'popup-message';
    const buttonContainer = document.createElement('div');
    buttonContainer.className = 'popup-button-container';
    const okButton = document.createElement('button');
    okButton.textContent = 'OK';
    okButton.className = 'popup-button';
    okButton.onclick = () => {
        document.body.removeChild(overlay);
        if (onConfirm)
            onConfirm();
    };
    buttonContainer.appendChild(okButton);
    if (onCancel) {
        const cancelButton = document.createElement('button');
        cancelButton.textContent = 'Cancel';
        cancelButton.className = 'popup-button';
        cancelButton.onclick = () => {
            document.body.removeChild(overlay);
            onCancel();
        };
        buttonContainer.appendChild(cancelButton);
    }
    popup.appendChild(messageElement);
    popup.appendChild(buttonContainer);
    overlay.appendChild(popup);
    document.body.appendChild(overlay);
    // Center the popup content
    popup.style.position = 'absolute';
    popup.style.left = '50%';
    popup.style.top = '50%';
    popup.style.transform = 'translate(-50%, -50%)';
    // Prevent scrolling on the body when popup is open
    document.body.style.overflow = 'hidden';
    // Re-enable scrolling when popup is closed
    const removeOverlay = () => {
        document.body.removeChild(overlay);
        document.body.style.overflow = '';
    };
    okButton.onclick = () => {
        removeOverlay();
        if (onConfirm)
            onConfirm();
    };
    if (onCancel) {
        const cancelButton = buttonContainer.lastElementChild;
        cancelButton.onclick = () => {
            removeOverlay();
            onCancel();
        };
    }
}
