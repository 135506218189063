import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, SolflareWalletAdapter, TorusWalletAdapter, CoinbaseWalletAdapter, } from '@solana/wallet-adapter-wallets';
import { connection } from './script';
import '@solana/wallet-adapter-react-ui/styles.css';
export const WalletContextProvider = ({ children }) => {
    const wallets = useMemo(() => [
        new PhantomWalletAdapter(),
        new SolflareWalletAdapter(),
        new TorusWalletAdapter(),
        new CoinbaseWalletAdapter(),
    ], []);
    return (_jsx(ConnectionProvider, { endpoint: connection.rpcEndpoint, children: _jsx(WalletProvider, { wallets: wallets, autoConnect: true, children: _jsx(WalletModalProvider, { children: children }) }) }));
};
