export const cardContent = [
    {
        title: "STAKING",
        content: `
        <div class="terminal-container">
            <div class="terminal-content" id="stakingInfo"></div>
        </div>
        <div class="bottom-content">
            <div class="input-container">
                <input type="number" id="stakeAmount" step="0.000000001" min="0" max="999999999.999999999" placeholder="Stake amount">
                <button id="maxStakeButton">MAX</button>
            </div>
            <div class="button-container">
                <button id="stakeButton" class="overlay-button">STAKE</button>
                <button id="unstakeButton" class="overlay-button">UNSTAKE ALL</button>
            </div>
        </div>
        `
    },
    {
        title: "TICKETS",
        content: `
        <div class="terminal-container">
            <div class="terminal-content" id="ticketsInfo"></div>
        </div>
        <div class="bottom-content">
            <div class="input-container">
                <input type="number" id="purchaseAmount" step="0.000001" min="0" placeholder="Purchase amount">
            </div>
            <div class="button-container">
                <button id="purchaseButton" class="overlay-button">BUY</button>
                <button id="redeemButton" class="overlay-button">REDEEM</button>
            </div>
        </div>
        `
    },
    {
        title: "MEME",
        content: `
        <div class="card-content meme-content">
            <div class="top-content">
                <label for="imageUpload" class="custom-button choose-file">Choose File</label>
                <input type="file" id="imageUpload" accept="image/*">
            </div>
            <div class="image-container">
                <canvas id="imageCanvas"></canvas>
            </div>
            <div class="button-container meme-buttons">
                <button id="stylesButton" class="overlay-button">STYLES</button>
                <button id="downloadButton" class="overlay-button">DOWNLOAD</button>
            </div>
        </div>
        `
    }
];
